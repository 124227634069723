import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class CompanyClient extends APIClient {
  async put({ token, id, data }) {
    const payload = {
      data,
      url: URLs.COMPANY,
      method: 'PUT',
      headers: { Authorization: token },
      args: {
        company_id: id,
      },
    }
    const response = await this.sendRequest(payload)
    return response.data.company
  }
  async delete({ token, id }) {
    const payload = {
      url: URLs.COMPANY,
      method: 'DELETE',
      headers: { Authorization: token },
      args: {
        company_id: id,
      },
    }
    const response = await this.sendRequest(payload)
    return response.data
  }
}
export default new CompanyClient()
