import { removeAbbreviationCompanyTypes, convertText } from 'Helpers/table/sort'
import { getName } from 'Helpers/invoice'
import { checkInclude } from 'Helpers/helpers'
import { getPayTypeList } from '@/objects/company/payType'
import jaconv from 'jaconv'

export function checkTableDisplay(displaySetting, displayKey) {
  try {
    if (displayKey === true) {
      return true
    } else if (displayKey in displaySetting) {
      return displaySetting[displayKey]
    } else {
      return false
    }
  } catch {
    return false
  }
}

export function changeTableSort(pagination, valueName) {
  try {
    if (pagination.sortBy !== valueName) {
      pagination.sortBy = valueName
      pagination.descending = false
    } else if (!pagination.descending) {
      pagination.descending = true
    } else {
      pagination.sortBy = ''
      pagination.descending = false
    }
    return pagination
  } catch (e) {
    console.log(e)
    return pagination
  }
}

export function sortTableData(headerValue, mode = null, sortParams, tableData) {
  if (mode === 'asc' || mode === 'desc') {
    sortParams.value = headerValue
    sortParams.mode = mode
  } else if (sortParams.value === headerValue && sortParams.mode === 'asc') {
    sortParams.value = headerValue
    sortParams.mode = 'desc'
  } else if (sortParams.value === headerValue && sortParams.mode === 'desc') {
    sortParams.value = 'id'
    sortParams.mode = 'asc'
  } else {
    sortParams.value = headerValue
    sortParams.mode = 'asc'
  }
  let value, modeList
  value = sortParams.value
  mode = sortParams.mode
  modeList = ['asc', 'desc']

  function getSortMode(value) {
    if (value.search('journal') === -1) {
      if (value.match(/^bank_data/)) {
        const [key1, key2, replaceCompanyType] = value.split('.')
        return [5, { key1, key2, replaceCompanyType }]
      }
      if (value.match(/^pay_type/)) {
        return [6, {}]
      }
      if (
        value.match(
          /(\.full_name)|(\.code)|(\.name)|(^current_invoice_status)|(^current_payment_status)/
        )
      ) {
        const [key1, key2] = value.split('.')
        return [4, { key1, key2 }]
      } else {
        return [1, {}]
      }
    }
    const textFieldExp = /(description)|(free_text_1)|(free_text_2)/
    const keys = value.split('.')
    const journalName = keys[1]
    if (journalName.match(textFieldExp)) {
      return [2, { journalName }]
    } else {
      return [3, { journalName, key: keys[2] }]
    }
  }
  const validate = (value, defaultValue = '') => {
    if (value === undefined || value === null) {
      return defaultValue
    } else {
      if (typeof value == 'string') {
        return jaconv.toZenKana(value)
      } else {
        return value
      }
    }
  }

  function mySortFunc(after, before) {
    const _after = validate(after)
    const _before = validate(before)
    if (_after == _before) {
      return 0
    }
    if (mode === 'asc') {
      // ascending
      return _before < _after ? 1 : -1
    } else {
      // descending
      return _before > _after ? 1 : -1
    }
  }

  function payTypeSortFunc(after, before) {
    const sortOrder = getPayTypeList()
    const _after = sortOrder.findIndex((obj) => obj.id === after)
    const _before = sortOrder.findIndex((obj) => obj.id === before)
    if (_after == _before) {
      return 0
    }
    if (mode === 'asc') {
      // ascending
      return _before < _after ? 1 : -1
    } else {
      // descending
      return _before > _after ? 1 : -1
    }
  }

  // sortMode 1: sort by sortParams, 2: sort by journal text,
  // 3: sort by journal name 4: sort by status 5: sort by bank_data 6: sort by pay_type
  const [sortMode, payload] = getSortMode(value)
  if (value && modeList.includes(mode)) {
    tableData.sort((after, before) => {
      if (sortMode === 1) {
        const _after = convertText(after[value])
        const _before = convertText(before[value])
        return mySortFunc(_after, _before)
      } else if (sortMode === 2) {
        const _after = convertText(after['journal'][0][payload.journalName])
        const _before = convertText(before['journal'][0][payload.journalName])
        return mySortFunc(_after, _before)
      } else if (sortMode === 3) {
        const _after = convertText(
          after['journal'][0][payload.journalName][payload.key]
        )
        const _before = convertText(
          before['journal'][0][payload.journalName][payload.key]
        )
        return mySortFunc(_after, _before)
      } else if (sortMode === 4) {
        const _after = convertText(after[payload.key1][payload.key2])
        const _before = convertText(before[payload.key1][payload.key2])
        return mySortFunc(_after, _before)
      } else if (sortMode === 5) {
        const { key1, key2, replaceCompanyType } = payload
        const bankAccountNameAfter = replaceCompanyType
          ? removeAbbreviationCompanyTypes(convertText(after[key1][key2]))
          : after[key1][key2]
        const bankAccountNameBefore = replaceCompanyType
          ? removeAbbreviationCompanyTypes(convertText(before[key1][key2]))
          : before[key1][key2]
        return mySortFunc(bankAccountNameAfter, bankAccountNameBefore)
      } else if (sortMode === 6) {
        return payTypeSortFunc(after[value], before[value])
      }
    })
  }
  return tableData
}

export function sortTableDataByIdList(idList, idKeyName, tableData) {
  if (idList.length > 0) {
    tableData.sort((after, before) => {
      let afterIndex = idList.findIndex((id) => id == after[idKeyName])
      let beforeIndex = idList.findIndex((id) => id == before[idKeyName])
      if (afterIndex == -1 && beforeIndex == -1) {
        return 0
      } else if (beforeIndex == -1) {
        return 1
      } else if (afterIndex < beforeIndex) {
        return -1
      } else if (afterIndex > beforeIndex) {
        return 1
      } else {
        return 0
      }
    })
  }
  return tableData
}

export function filterTableByText(search_bar, items) {
  if (!search_bar || search_bar.trim() === '') {
    return items
  }
  const payTypes = {
    1: '振込',
    2: '引落',
    3: 'クレジット',
    4: '手形',
    5: '現金',
    6: '海外送金',
    0: 'その他',
  }
  const statuskeys = [
    'upload',
    'update',
    'export',
    'approve1',
    'approve2',
    'approve3',
    'approve4',
    'approve5',
    'update_bank',
    'pay_confirm',
    'pay_confirm2',
    'pay_confirm3',
    'pay_approve',
    'paid',
    'pay_export',
  ]
  const actionDateKeys = statuskeys.map((key) => `date_${key}`)
  const actionUserKeys = statuskeys.map((key) => {
    if (key === 'update_bank') {
      return 'user_update_bank'
    }
    const rank = key.match('[0-9]') ? key.match('[0-9]')[0] : null
    if (rank) {
      return `${key.slice(0, -1)}_user${rank}`
    } // approve_user1
    return `${key}_user`
  })
  const checkGensen = (gensenzei, text) =>
    gensenzei && checkInclude('源泉', text)
  const checkPayTypes = (payType, text) => checkInclude(payTypes[payType], text)
  const checkDepartment = (department, text) =>
    department && checkInclude(department.name, text)
  const checkBanks = (item, text) => {
    const bankData = item.bank_data
    const result =
      bankData &&
      Object.keys(bankData).some((key) => checkInclude(bankData[key], text))
    return result
  }
  const checkUser = (user, text) => {
    return (
      user && ['full_name', 'code'].some((key) => checkInclude(user[key], text))
    )
  }
  const checkStatusUser = (item, text) => {
    return actionUserKeys.some((key) => checkUser(item[key], text))
  }

  const singleFilterKeys = [
    'reading_date',
    'issue_date',
    'pay_date',
    'image_local_id',
    'image__upload_source',
    'company_name',
    'confirm_user',
    'approve_user',
    'note_1',
    'note_2',
    'note_3',
    'upload_type',
    'debit_amount_total',
    'debit_tax_amount_total',
    'gensen_amount',
    'pay_amount',
    ...actionDateKeys,
  ]
  const checkSingleKey = (item, text) =>
    singleFilterKeys.some((key) => checkInclude(item[key], text))

  const invoice_items = items.filter((item) => {
    const foundItem =
      checkSingleKey(item, search_bar) ||
      checkGensen(item.gensenzei, search_bar) ||
      checkPayTypes(item.pay_type, search_bar) ||
      checkBanks(item, search_bar) ||
      checkStatusUser(item, search_bar) ||
      checkDepartment(item.department, search_bar)
    if (foundItem) {
      return true
    }

    for (let journal of item.journal) {
      let keys = [
        'debit_account_title',
        'debit_sub_account',
        'debit_department',
        'debit_tax_class',
        'debit_vendor',
        'debit_project',
        'debit_segment',
        'debit_item',
        'debit_tag',
        'debit_walletable',
        'credit_account_title',
        'credit_sub_account',
        'credit_department',
        'credit_tax_class',
        'credit_vendor',
        'credit_project',
        'credit_segment',
        'credit_item',
        'credit_tag',
        'credit_walletable',

        'description',
        'free_text_1',
        'free_text_2',
      ]

      try {
        for (let key of keys) {
          var value = ''
          if (!key.includes('debit') && !key.includes('credit')) {
            value = journal[key]
          } else {
            value = getName(journal[key])
          }
          if (checkInclude(value, search_bar)) {
            return true
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  }, search_bar)
  return invoice_items
}

export function applyMultiSelect(
  oprationTypeNumber,
  items,
  { itemsPerPage, page, selectFunction, disSelectFunction }
) {
  const selectActions = {
    1: 'selectAll',
    2: 'selectCurrentPage',
    3: 'deselectAll',
    4: 'deselectCurrentPage',
  }
  const operation = selectActions[oprationTypeNumber]
  if (operation === 'selectAll') {
    return selectFunction(items)
  } else if (operation === 'deselectAll') {
    return disSelectFunction(items)
  } else {
    const dataList = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    if (operation === 'selectCurrentPage') {
      return selectFunction(dataList)
    } else if (operation === 'deselectCurrentPage') {
      return disSelectFunction(dataList)
    }
  }
}
