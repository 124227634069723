import jaconv from 'jaconv'

const getAbbreviationCompanyTypesExpression = () => {
  const companyTypes = [
    { name: '株式会社', value: 'カ' },
    { name: '有限会社', value: 'ユ' },
    { name: '合名会社', value: 'メ' },
    { name: '合資会社', value: 'シ' },
    { name: '合同', value: 'ド' },
    { name: '一般社団法人', value: 'イ' },
    // {name: "医療法人", value: "イ"},
    { name: '公正保護法人', value: 'ホゴ' },
    { name: '相互会社', value: 'ソ' },
    { name: '財団法人', value: 'ザイ' },
    { name: '社団法人', value: 'シヤ' },
    { name: '宗教法人', value: 'シユウ' },
    { name: '学校法人', value: 'ガク' },
    { name: '社会福祉法人', value: 'フク' },
    { name: '特定非営利活動法人', value: 'トクヒ' },
    { name: '独立行政法人', value: 'ドク' },
    { name: '弁護士法人', value: 'ベン' },
    { name: '有限責任中間法人', value: 'チユウ' },
    //{name: "無限責任中間法人", value: "チユウ"},
    { name: '行政書士法人', value: 'ギヨ' },
    { name: '司法書士法人', value: 'シホウ' },
    { name: '税理士法人', value: 'ゼイ' },
    { name: '国立大学法人', value: 'ダイ' },
    //{name: "公立大学法人", value: "ダイ"},
    { name: '農事組合法人', value: 'ノウ' },
    { name: '管理組合法人', value: 'カンリ' },
    { name: '社会保険労務士法人', value: 'ロウム' },
  ]
  const officeTypes = [
    { name: '出張所', value: 'シユツ' },
    { name: '営業所', value: 'エイ' },
  ]
  const businessTypes = [
    { name: '連合会', value: 'レン' },
    { name: '共済組合', value: 'キヨウサイ' },
    { name: '協同組合', value: 'キヨウクミ' },
    { name: '生命保険', value: 'セイメイ' },
    { name: '海上火災保険', value: 'カイジヨウ' },
    { name: '火災海上保険', value: 'カサイ' },
    { name: '健康保険組合', value: 'ケンポ' },
    // {name: "国民健康保険組合", value: "ケンポ"},
    { name: '国民健康保険団体連合会', value: 'コクホレン' },
    { name: '社会保険診療報酬支払基金', value: 'シヤホ' },
    { name: '厚生年金基金', value: 'コウネン' },
    { name: '従業員組合', value: 'ジユウクミ' },
    { name: '労働組合', value: 'ロウクミ' },
    { name: '生活協同組合', value: 'セイキヨウ' },
    { name: '食糧販売協同組合', value: 'シヨクハンキヨウ' },
    { name: '国家公務員共済組合連合会', value: 'コクキヨウレン' },
    { name: '農業協同組合連合会', value: 'ノウキヨウレン' },
    { name: '経済農業協同組合連合会', value: 'ケイザイレン' },
    { name: '共済農業協同組合連合会', value: 'キヨウサイレン' },
    { name: '漁業協同組合', value: 'ギヨキヨウ' },
    { name: '漁業協同組合連合会', value: 'ギヨレン' },
    { name: '公共職業安定所', value: 'シヨクアン' },
    { name: '社会福祉協議会', value: 'シヤキヨウ' },
    { name: '特別養護老人ホーム', value: 'トクヨウ' },
    { name: '有限責任事業組合', value: 'ユウクミ' },
  ]
  const allTypes = [...companyTypes, ...businessTypes, ...officeTypes]
  const prefixExpression = new RegExp(
    allTypes
      .map(
        //カ)xxx
        (obj) => `^${obj.value}[)）]`
      )
      .join('|')
  )

  const suffixExpression = new RegExp(
    allTypes
      .map(
        // xxx (カ
        (obj) => `[(（]${obj.value}$`
      )
      .join('|')
  )

  const middleExpression = new RegExp(
    allTypes.map((obj) => `[(（]${obj.value}[)）]`).join('|')
  )

  return {
    prefixExpression,
    suffixExpression,
    middleExpression,
  }
}
const { prefixExpression, suffixExpression, middleExpression } =
  getAbbreviationCompanyTypesExpression()

export const removeAbbreviationCompanyTypes = (text) => {
  return jaconv
    .toZenKana(text)
    .replace(prefixExpression, '')
    .replace(suffixExpression, '')
    .replace(middleExpression, '')
}

function convertToSeion(text) {
  const convertList = {
    が: 'か',
    ぎ: 'き',
    ぐ: 'く',
    げ: 'け',
    ご: 'こ',
    ざ: 'さ',
    じ: 'し',
    ず: 'す',
    ぜ: 'せ',
    ぞ: 'そ',
    だ: 'た',
    ぢ: 'ち',
    づ: 'つ',
    で: 'て',
    ど: 'と',
    ば: 'は',
    び: 'ひ',
    ぶ: 'ふ',
    べ: 'へ',
    ぼ: 'ほ',
    ヴ: 'ウ',
    ガ: 'カ',
    ギ: 'キ',
    グ: 'ク',
    ゲ: 'ケ',
    ゴ: 'コ',
    ザ: 'サ',
    ジ: 'シ',
    ズ: 'ス',
    ゼ: 'セ',
    ゾ: 'ソ',
    ダ: 'タ',
    ヂ: 'チ',
    ヅ: 'ツ',
    デ: 'テ',
    ド: 'ト',
    バ: 'ハ',
    ビ: 'ヒ',
    ブ: 'フ',
    ベ: 'ヘ',
    ボ: 'ホ',
    ぱ: 'は',
    ぴ: 'ひ',
    ぷ: 'ふ',
    ぺ: 'へ',
    ぽ: 'ほ',
    パ: 'ハ',
    ピ: 'ヒ',
    プ: 'フ',
    ペ: 'ヘ',
    ポ: 'ホ',
  }

  const zenKanaText = jaconv.toZenKana(text)
  const arrayText = zenKanaText.split('')
  const convertArrayText = []
  for (let character of arrayText) {
    character = convertList[character] || character
    convertArrayText.push(character)
  }
  const convertText = convertArrayText.join('')

  return convertText
}

export const convertText = (text) => {
  if (!text || typeof text === 'number' || typeof text === 'object') {
    return text || ''
  } else {
    const upperCaseText = text.toUpperCase()
    return convertToSeion(upperCaseText)
  }
}

/*
test
const texts = [
  'カ)a',
  'a(カ',
  'ssss(カ)a',
  'ssssカ)a',
  'a(カbbbb',
  'カサイリンカイコウエン',
  'シ)b',
  'b(シ',
  'b(シ)b',
  'シカゴシ',
  'ロウム)c',
  'c(ロウム',
  'ロウムロウム',
  'フク)d',
  'd(フク',
  'フクフク',
  'ケンポ)e',
  'エイ)f'
]

for (const text of texts) {
  console.log(removeAbbreviationCompanyTypes(text))
}
*/
