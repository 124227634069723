import { AsYouType } from 'libphonenumber-js'

export const getJounralName = (journal, attrName) => {
  if (!journal) {
    return ''
  }
  const master = journal[attrName]
  return (master && master.name) || ''
}

export const getPatternLabel = (pattern) => {
  const displayPatternName = `【 ${pattern.name} 】`
  const companyJournal = pattern.company_journals[0]
  const displayAccountTitleName = getJounralName(
    companyJournal,
    'debit_account_title'
  )
  const displayDepartmentName = getJounralName(
    companyJournal,
    'debit_department'
  )
  return `${displayPatternName} ${displayAccountTitleName} ${displayDepartmentName}`
}

export const convertForImportWidget = (company) => {
  company.company_phonenumber = new AsYouType('JP').input(
    company.company_phonenumber
  )
  company.selected = true
  company.display = true
  company.showAll = false
  return company
}
