<template>
  <div class="py-2 px-3">
    <template v-for="(item, i) in items">
      <div :key="i">
        <v-row
          v-if="
            i > 0 &&
            showAll &&
            (getName(item.debit_account_title) ||
              getName(item.credit_account_title))
          "
        >
          <v-divider />
        </v-row>
        <v-row v-if="i == 0 && getName(item.debit_account_title)">
          <span v-tooltip="getAmountString(item.debit_amount)">
            <s-chip-dark color="blue-500"> 借 </s-chip-dark>
          </span>
          <span
            v-if="getName(item.debit_account_title)"
            v-tooltip="
              definition.account_title + ' ' + getCode(item.debit_account_title)
            "
            class="ml-2"
          >
            {{ getName(item.debit_account_title) }}
          </span>
          <span
            v-if="getName(item.debit_sub_account)"
            v-tooltip="
              definition.sub_account + ' ' + getCode(item.debit_sub_account)
            "
            class="ml-2"
          >
            {{ getName(item.debit_sub_account) }}
          </span>
          <span
            v-if="getName(item.debit_department)"
            v-tooltip="
              definition.department + ' ' + getCode(item.debit_department)
            "
            class="ml-2"
          >
            {{ getName(item.debit_department) }}
          </span>
          <span
            v-if="getName(item.debit_vendor)"
            v-tooltip="definition.vendor + ' ' + getCode(item.debit_vendor)"
            class="ml-2"
          >
            {{ getName(item.debit_vendor) }}
          </span>
          <span
            v-if="getName(item.debit_project)"
            v-tooltip="definition.project + ' ' + getCode(item.debit_project)"
            class="ml-2"
          >
            {{ getName(item.debit_project) }}
          </span>
          <span
            v-if="getName(item.debit_segment)"
            v-tooltip="definition.segment + ' ' + getCode(item.debit_segment)"
            class="ml-2"
          >
            {{ getName(item.debit_segment) }}
          </span>
          <span
            v-if="getName(item.debit_item)"
            v-tooltip="definition.item + ' ' + getCode(item.debit_item)"
            class="ml-2"
          >
            {{ getName(item.debit_item) }}
          </span>
          <span
            v-if="getName(item.debit_tag)"
            v-tooltip="definition.tag + ' ' + getCode(item.debit_tag)"
            class="ml-2"
          >
            {{ getName(item.debit_tag) }}
          </span>
          <span
            v-if="getName(item.debit_walletable)"
            v-tooltip="
              definition.walletable + ' ' + getCode(item.debit_walletable)
            "
            class="ml-2"
          >
            {{ getName(item.debit_walletable) }}
          </span>
          <span
            v-if="getName(item.debit_tax_class)"
            v-tooltip="
              definition.tax_class + ' ' + getCode(item.debit_tax_class)
            "
            class="ml-2"
          >
            {{ getName(item.debit_tax_class) }}
          </span>
        </v-row>
        <v-row
          v-if="i == 0 && getName(item.credit_account_title)"
          style="margin-top: 2px"
        >
          <span v-tooltip="getAmountString(item.credit_amount)">
            <s-chip-dark color="green-600"> 貸 </s-chip-dark>
          </span>
          <span
            v-if="getName(item.credit_account_title)"
            v-tooltip="
              definition.account_title +
              ' ' +
              getCode(item.credit_account_title)
            "
            class="ml-2"
          >
            {{ getName(item.credit_account_title) }}
          </span>
          <span
            v-if="getName(item.credit_sub_account)"
            v-tooltip="
              definition.sub_account + ' ' + getCode(item.credit_sub_account)
            "
            class="ml-2"
          >
            {{ getName(item.credit_sub_account) }}
          </span>
          <span
            v-if="getName(item.credit_department)"
            v-tooltip="
              definition.department + ' ' + getCode(item.credit_department)
            "
            class="ml-2"
          >
            {{ getName(item.credit_department) }}
          </span>
          <span
            v-if="getName(item.credit_vendor)"
            v-tooltip="definition.vendor + ' ' + getCode(item.credit_vendor)"
            class="ml-2"
          >
            {{ getName(item.credit_vendor) }}
          </span>
          <span
            v-if="getName(item.credit_project)"
            v-tooltip="definition.project + ' ' + getCode(item.credit_project)"
            class="ml-2"
          >
            {{ getName(item.credit_project) }}
          </span>
          <span
            v-if="getName(item.credit_segment)"
            v-tooltip="definition.segment + ' ' + getCode(item.credit_segment)"
            class="ml-2"
          >
            {{ getName(item.credit_segment) }}
          </span>
          <span
            v-if="getName(item.credit_item)"
            v-tooltip="definition.item + ' ' + getCode(item.credit_item)"
            class="ml-2"
          >
            {{ getName(item.credit_item) }}
          </span>
          <span
            v-if="getName(item.credit_tag)"
            v-tooltip="definition.tag + ' ' + getCode(item.credit_tag)"
            class="ml-2"
          >
            {{ getName(item.credit_tag) }}
          </span>
          <span
            v-if="getName(item.credit_walletable)"
            v-tooltip="
              definition.walletable + ' ' + getCode(item.credit_walletable)
            "
            class="ml-2"
          >
            {{ getName(item.credit_walletable) }}
          </span>
          <span
            v-if="getName(item.credit_tax_class)"
            v-tooltip="
              definition.tax_class + ' ' + getCode(item.credit_tax_class)
            "
            class="ml-2"
          >
            {{ getName(item.credit_tax_class) }}
          </span>
        </v-row>
        <v-row v-if="i > 0 && getName(item.debit_account_title) && showAll">
          <span v-tooltip="getAmountString(item.debit_amount)">
            <s-chip-dark color="blue-500"> 借 </s-chip-dark>
          </span>
          <span
            v-if="getName(item.debit_account_title)"
            v-tooltip="
              definition.account_title + ' ' + getCode(item.debit_account_title)
            "
            class="ml-2"
          >
            {{ getName(item.debit_account_title) }}
          </span>
          <span
            v-if="getName(item.debit_sub_account)"
            v-tooltip="
              definition.sub_account + ' ' + getCode(item.debit_sub_account)
            "
            class="ml-2"
          >
            {{ getName(item.debit_sub_account) }}
          </span>
          <span
            v-if="getName(item.debit_department)"
            v-tooltip="
              definition.department + ' ' + getCode(item.debit_department)
            "
            class="ml-2"
          >
            {{ getName(item.debit_department) }}
          </span>
          <span
            v-if="getName(item.debit_vendor)"
            v-tooltip="definition.vendor + ' ' + getCode(item.debit_vendor)"
            class="ml-2"
          >
            {{ getName(item.debit_vendor) }}
          </span>
          <span
            v-if="getName(item.debit_project)"
            v-tooltip="definition.project + ' ' + getCode(item.debit_project)"
            class="ml-2"
          >
            {{ getName(item.debit_project) }}
          </span>
          <span
            v-if="getName(item.debit_segment)"
            v-tooltip="definition.segment + ' ' + getCode(item.debit_segment)"
            class="ml-2"
          >
            {{ getName(item.debit_segment) }}
          </span>
          <span
            v-if="getName(item.debit_item)"
            v-tooltip="definition.item + ' ' + getCode(item.debit_item)"
            class="ml-2"
          >
            {{ getName(item.debit_item) }}
          </span>
          <span
            v-if="getName(item.debit_tag)"
            v-tooltip="definition.tag + ' ' + getCode(item.debit_tag)"
            class="ml-2"
          >
            {{ getName(item.debit_tag) }}
          </span>
          <span
            v-if="getName(item.debit_walletable)"
            v-tooltip="
              definition.walletable + ' ' + getCode(item.debit_walletable)
            "
            class="ml-2"
          >
            {{ getName(item.debit_walletable) }}
          </span>
          <span
            v-if="getName(item.debit_tax_class)"
            v-tooltip="
              definition.tax_class + ' ' + getCode(item.debit_tax_class)
            "
            class="ml-2"
          >
            {{ getName(item.debit_tax_class) }}
          </span>
        </v-row>
        <v-row
          v-if="i > 0 && getName(item.credit_account_title) && showAll"
          style="margin-top: 2px"
        >
          <span v-tooltip="getAmountString(item.credit_amount)">
            <s-chip-dark color="green-600"> 貸 </s-chip-dark>
          </span>
          <span
            v-if="getName(item.credit_account_title)"
            v-tooltip="
              definition.account_title +
              ' ' +
              getCode(item.credit_account_title)
            "
            class="ml-2"
          >
            {{ getName(item.credit_account_title) }}
          </span>
          <span
            v-if="getName(item.credit_sub_account)"
            v-tooltip="
              definition.sub_account + ' ' + getCode(item.credit_sub_account)
            "
            class="ml-2"
          >
            {{ getName(item.credit_sub_account) }}
          </span>
          <span
            v-if="getName(item.credit_department)"
            v-tooltip="
              definition.department + ' ' + getCode(item.credit_department)
            "
            class="ml-2"
          >
            {{ getName(item.credit_department) }}
          </span>
          <span
            v-if="getName(item.credit_vendor)"
            v-tooltip="definition.vendor + ' ' + getCode(item.credit_vendor)"
            class="ml-2"
          >
            {{ getName(item.credit_vendor) }}
          </span>
          <span
            v-if="getName(item.credit_project)"
            v-tooltip="definition.project + ' ' + getCode(item.credit_project)"
            class="ml-2"
          >
            {{ getName(item.credit_project) }}
          </span>
          <span
            v-if="getName(item.credit_segment)"
            v-tooltip="definition.segment + ' ' + getCode(item.credit_segment)"
            class="ml-2"
          >
            {{ getName(item.credit_segment) }}
          </span>
          <span
            v-if="getName(item.credit_item)"
            v-tooltip="definition.item + ' ' + getCode(item.credit_item)"
            class="ml-2"
          >
            {{ getName(item.credit_item) }}
          </span>
          <span
            v-if="getName(item.credit_tag)"
            v-tooltip="definition.tag + ' ' + getCode(item.credit_tag)"
            class="ml-2"
          >
            {{ getName(item.credit_tag) }}
          </span>
          <span
            v-if="getName(item.credit_walletable)"
            v-tooltip="
              definition.walletable + ' ' + getCode(item.credit_walletable)
            "
            class="ml-2"
          >
            {{ getName(item.credit_walletable) }}
          </span>
          <span
            v-if="getName(item.credit_tax_class)"
            v-tooltip="
              definition.tax_class + ' ' + getCode(item.credit_tax_class)
            "
            class="ml-2"
          >
            {{ getName(item.credit_tax_class) }}
          </span>
        </v-row>
        <div v-if="i == 1 && !showAll" class="text-right pt-2">
          <v-btn
            x-small
            text
            color="blue"
            class="px-0"
            @click="onClickShowJournals"
          >
            ..他明細を表示
            <v-icon x-small> feather-chevron-down </v-icon>
          </v-btn>
        </div>
        <div
          v-if="i != 0 && i == items.length - 1 && showAll"
          class="text-right pt-2"
        >
          <v-btn
            x-small
            text
            color="blue"
            class="px-0"
            @click="onClickShowJournals"
          >
            非表示
            <v-icon x-small> feather-chevron-up </v-icon>
          </v-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    definition: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getName(item) {
      try {
        return item.name
      } catch (e) {
        return ''
      }
    },
    getCode(item) {
      try {
        return item.code
      } catch (e) {
        return ''
      }
    },
    getAmountString(journalAmount) {
      return typeof journalAmount === 'number'
        ? journalAmount.toString() + '円'
        : ''
    },
    onClickShowJournals() {
      this.$emit('click:show-all')
    },
  },
}
</script>
