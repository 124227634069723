import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class CompaniesClient extends APIClient {
  async get({ token, params }) {
    const payload = {
      url: URLs.COMPANIES,
      method: 'GET',
      headers: { Authorization: token },
      params,
    }
    const response = await this.sendRequest(payload)
    return response.data
  }
  async delete({ token, ids }) {
    const payload = {
      url: URLs.COMPANIES,
      method: 'DELETE',
      headers: { Authorization: token },
      data: { id: ids },
    }
    await this.sendRequest(payload)
  }
}
export default new CompaniesClient()
