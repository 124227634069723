// const payType = {id: 0, name: ""}
export const getPayTypeList = () => {
  const payTypeList = [
    { id: 1, name: '振込' },
    { id: 2, name: '引落' },
    { id: 3, name: 'クレジット' },
    { id: 4, name: '手形' },
    { id: 5, name: '現金' },
    { id: 6, name: '海外送金' },
    { id: 0, name: 'その他' },
  ]
  return Object.freeze(payTypeList)
}
