import CompanyClient from '@/clients/company/company'
import CompaniesClient from '@/clients/company/companies'
import CompanyBatchClient from '@/clients/company/companyBatch'
import { convertJournalObjectToID } from 'Helpers/journal'
import { getUserBankSimpleText } from '@/helpers/account/index'

export const getCompanies = async (token, params) => {
  const { companies } = await CompaniesClient.get({ token, params }).catch(
    () => []
  )
  return companies
}

export const companyService = {
  async get(token, { id, name, phoneNumber, readonly }) {
    const params = id
      ? { id }
      : { company_name: name, company_phonenumber: phoneNumber, readonly }
    const { companies } = await CompaniesClient.get({ token, params }).catch(
      () => []
    )
    return companies[0]
  },
  async update(token, company) {
    const data = createCompanyParams(company)
    const changedCompany = await CompanyClient.put({
      token,
      id: company.id,
      data,
    })
    changedCompany.user_bank.simpletext = getUserBankSimpleText(
      changedCompany.user_bank
    )
    return changedCompany
  },
  async patch({ token, id, companyName, companyPhonenumber, companyAddress }) {
    const data = {
      id,
      company_name: companyName,
      company_address: companyAddress,
      company_phonenumber: companyPhonenumber,
    }
    const changedComapny = await CompanyClient.put({ token, id, data })
    return changedComapny
  },
  async delete(token, id) {
    return await CompanyClient.delete({ token, id })
  },
}

export const companyBatchService = {
  async bulkCreate(token, companies) {
    const convertParameters = (company) => {
      company.selected = undefined
      company.display = undefined
      const convertedJournals = company.company_pattern.company_journals.map(
        (journal) => {
          return convertJournalObjectToID(journal)
        }
      )
      company.company_pattern.company_journals = convertedJournals
      return company
    }
    const convertedCompanies = JSON.parse(JSON.stringify(companies)).map(
      (company) => convertParameters(company)
    )
    const result = await CompanyBatchClient.post(token, convertedCompanies)
    return result
  },
}

export const updateCompany = ({
  token,
  id,
  companyName,
  companyPhonenumber,
  companyAddress,
}) => {
  // change
  const data = {
    company_name: companyName,
    company_phonenumber: companyPhonenumber,
    company_address: companyAddress,
  }
  return CompanyClient.put({ token, id, data })
}

export const getCompanyListContent = (company) => {
  /**
   * {
   *  id: Number
   *  name: String
   *  address: String
   *  phoneNumber: String
   *  patterns []<companyPattern>
   * }
   */
  return {
    id: company.id,
    name: company.company_name,
    address: company.company_address,
    phoneNumber: company.company_phonenumber,
    patterns: company.patterns || [],
  }
}

export const getCompanyFromSnake = (companySnake) => {
  const formCompany = {}
  formCompany.id = companySnake.id
  formCompany.companyName = companySnake.company_name
  formCompany.companyAddress = companySnake.company_address
  formCompany.companyPhonenumber = companySnake.company_phonenumber
  formCompany.companyEmail = companySnake.email
  formCompany.invoiceIssuerNumber = companySnake.invoice_issuer_number
  formCompany.gensenzei = companySnake.gensenzei
  formCompany.gensenCode = companySnake.gensen_code
  formCompany.gensenTotalTargets = companySnake.gensen_total_targets
  formCompany.payType = companySnake.pay_type
  formCompany.payMonth = companySnake.pay_month
  formCompany.payDay = companySnake.pay_day
  ;(formCompany.bank = {
    name: companySnake.bank_name,
    code: companySnake.bank_code,
  }),
    (formCompany.bankBranch = {
      name: companySnake.bank_branch_name,
      code: companySnake.bank_branch_code,
    }),
    (formCompany.userBank = companySnake.user_bank)
  formCompany.paidByCustomer = companySnake.paid_by_customer
  formCompany.bankName = companySnake.bank_name
  formCompany.bankCode = companySnake.bank_code
  formCompany.bankBranchName = companySnake.bank_branch_name
  formCompany.bankBranchCode = companySnake.bank_branch_code
  formCompany.paySiteName = companySnake.pay_site_name
  formCompany.bankAccountName = companySnake.bank_account_name
  formCompany.bankAccountType = companySnake.bank_account_type
  formCompany.bankAccountNumber = companySnake.bank_account_number
  formCompany.readonly = companySnake.readonly
  return formCompany
}

export const createCompanyParams = (formCompany) => {
  const getAttr = (obj, name) => {
    return obj && obj[name]
  }
  const data = {}

  data.id = formCompany.id
  data.company_name = formCompany.companyName
  data.company_address = formCompany.companyAddress
  data.company_phonenumber = formCompany.companyPhonenumber
  data.invoice_issuer_number = formCompany.invoiceIssuerNumber
  data.email = formCompany.companyEmail
  data.gensenzei = formCompany.gensenzei
  data.gensen_code = formCompany.gensenCode
  data.gensen_total_targets = formCompany.gensenTotalTargets
  data.pay_type = formCompany.payType
  data.pay_month = formCompany.payMonth
  data.pay_day = formCompany.payDay
  data.user_bank_id = formCompany.userBank.id
  data.paid_by_customer = formCompany.paidByCustomer
  data.bank_name = getAttr(formCompany.bank, 'name') || ''
  data.bank_code = getAttr(formCompany.bank, 'code') || ''
  data.bank_branch_name = getAttr(formCompany.bankBranch, 'name') || ''
  data.bank_branch_code = getAttr(formCompany.bankBranch, 'code') || ''
  data.bank_account_name = formCompany.bankAccountName || ''
  data.bank_account_type = formCompany.bankAccountType || ''
  data.bank_account_number = formCompany.bankAccountNumber || ''

  return data
}

export const getSnackCompany = () => {
  return JSON.parse(
    JSON.stringify({
      id: 0,
      company_phonenumber: '',
      pay_month: null,
      gensen_total_targets: 0,
      gensenzei: false,
      scanned_company_name: '',
      bank_account_number: null,
      bank_account_type: null,
      pay_type_name: '',
      bank_account_name: null,
      gensen_code: '',
      bank_branch_name: null,
      scanned_company_phonenumber: '',
      company_name: '',
      bank_code: null,
      email: '',
      bank_branch_code: null,
      pay_day: null,
      company_address: '',
      bank_name: null,
      pay_type: null,
      pay_site_name: '',
      patterns: [],
    })
  )
}
