<template>
  <div>
    <v-menu
      v-model="showMenu"
      offset-x
      right
      z-index="9999"
      transition="slide-x-transition"
      nudge-top="7"
      nudge-right="10"
      close-delay="300"
      :close-on-content-click="false"
      @input="$emit('input', $event)"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-tooltip="tooltip" v-bind="attrs" v-on="on">
          <v-checkbox
            :value="computedAllSelected"
            :indeterminate="computedIndeterminate"
            hide-details
            color="primary"
            readonly
          />
        </span>
      </template>

      <v-card class="sweeep-popover">
        <v-list dense class="fs-11">
          <div v-for="(item, i) in listItems" :key="i">
            <v-list-item link @click="onClick(item)">
              <i :class="'mr-3 ' + item.icon" />
              {{ item.text }}
            </v-list-item>
          </div>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: null,
      required: false,
    },
    computeValueWithItems: {
      //true: itemsのselectedからcheckboxのvalueを算出する  false:propsの値でcheckboxのvalueを決定する
      type: Boolean,
      default: false,
      required: false,
    },
    allSelected: {
      type: Boolean,
      default: false,
      required: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
      required: false,
    },
    tooltip: {
      type: String,
      default: '全選択指定方法を表示',
      required: false,
    },
  },
  data() {
    return {
      showMenu: false,
      listItems: [
        {
          value: 1,
          text: '全選択',
          icon: 'feather-check-square',
          message: '表示結果を全て選択しました。',
        },
        {
          value: 2,
          text: '現在のページ選択',
          icon: 'feather-check-square',
          message:
            '現在のページのデータを全て選択しました。\n他のページの選択状態は変更されません。',
        },
        {
          value: 3,
          text: '全解除',
          icon: 'feather-square',
          message: '表示結果を全て選択解除しました。',
        },
        {
          value: 4,
          text: '現在のページ解除',
          icon: 'feather-square',
          message:
            '現在のページのデータを全て解除しました。\n他のページの選択状態は変更されません。',
        },
      ],
    }
  },
  computed: {
    computedAllSelected: function () {
      if (this.computeValueWithItems) {
        if (this.items.length > 0) {
          return this.items.every((item) => item.selected === true)
        } else {
          return false
        }
      } else {
        return this.allSelected
      }
    },
    computedIndeterminate: function () {
      if (this.computeValueWithItems) {
        const isAllSelected = this.computedAllSelected
        const isAllUnSelected = this.items.every((item) => !item.selected)
        return !isAllSelected && !isAllUnSelected
      } else {
        return this.indeterminate
      }
    },
  },
  methods: {
    onClick(item) {
      const value = item.value
      const message = item.message
      this.$emit('click:item', { value, message })
      this.showMenu = false
    },
  },
}
</script>
